import { InputNumber, Modal, Switch } from "antd"
import React from "react"
import { HRButton } from "../../../../components/button"
import { ICChevronDown, ICDelete, ICDraggable, ICEdit, ICFolder } from "../../../../icons"
import { EQuestionType, QuestionTypeMapping, TChoiceQuestion, TQuestion } from "./type"
import classNames from 'classnames'
import { AnswerWrapper } from "./editor"
import styled from 'styled-components';
import Highlighter from "react-highlight-words";
import { useCreateTestPageContext } from "../.."
import { HRTooltip } from "../../../../components/tooltip"
import { htmlToText } from "../../../../utils/htmlToText"
import i18next from "../../../../i18n"
import parse from 'html-react-parser';

const Wrapper = styled.div`
    p {
        margin-bottom: 0;
    }
`;

type QuestionRowProps = {
    question: TQuestion,
    index: number,
    isFirst?: boolean,
    isLast?: boolean,
    hasActiveWeight?: boolean,
    onChange: (index: number, question: TQuestion) => void
    onDelete: (index: number) => void
    onUpdate: (index: number) => void,
    onCollapsed: (index: number, question: TQuestion) => void
    onMove: () => void
}
export const QuestionRow: React.FC<QuestionRowProps> = ({
    question,
    index,
    isFirst = false,
    isLast = false,
    hasActiveWeight = false,
    onChange,
    onDelete,
    onUpdate,
    onCollapsed,
    onMove
}) => {
    const {
        formFilter: {
            title
        },
    } = useCreateTestPageContext();
    const onToggleActive = React.useCallback(() => {
        onChange(index, {
            ...question,
            isActive: !question.isActive
        })
    }, [question, index, onChange]);

    const onChangeCollapsed = React.useCallback(() => {
        onCollapsed(index, {
            ...question,
            isExpand: !question.isExpand
        });
    }, [question, index, onCollapsed]);

    const questionNo = React.useMemo(() => {
        return `No.${(index + 1).toString().padStart(2, "0")}`
    }, [index]);

    const [showDeleteModal, setShowDeleteModal] = React.useState(false);
    const onUpdateWeight = React.useCallback((val: number | null, index: number) => {
        onChange(index, {
            ...question,
            weight: val || 0
        })
    }, [onChange, question]);

    return <div className={classNames(!question.isActive ? "bg-surface-disabled-low text-disabled" : "text-high-em",
        "group py-3 px-5 border-[1px] border-transparent hover:border-purple text-standard",
        isFirst ? "rounded-t-[8px]" : "",
        isLast ? "rounded-b-[8px]" : "",
    )}>
        <div className="lg:flex items-center justify-between">
            <div className={classNames(!question.isActive ? "opacity-40" : "", "grid grid-cols-[auto_auto_120px_1fr] gap-[8px] items-center")}>
                <span className="relative p-1 cursor-pointer opacity-100 lg:opacity-0 group-hover:opacity-100">
                    <HRTooltip content={i18next.t('createTest.sortQuestion')}
                        placement={'top'}
                        childNode={<><ICDraggable fill="#7357FF" /></>}>
                    </HRTooltip>
                </span>
                <Switch checked={question.isActive} onChange={onToggleActive} />
                <div>
                    <span className="px-[8px] py-[4px] rounded-[144px] text-sub-medium text-primary border border-solid border-outline-primary-low bg-surface-primary-accent-1">
                        {QuestionTypeMapping[question.type]}
                    </span>
                </div>
                <Wrapper className="grid grid-cols-[auto_1fr] gap-[8px] items-center">
                    <span className="text-standard-bold text-high-em">
                        {questionNo}
                    </span>
                    <HRTooltip
                        content={question.content}
                        childNode={(
                            <span className="line-clamp-1 w-full text-high-em text-standard">
                                <Highlighter
                                    highlightClassName="text-highlight"
                                    searchWords={[`${title || ''}`]}
                                    autoEscape={true}
                                    textToHighlight={htmlToText(question.content)}
                                />
                            </span>
                        )}
                    >
                    </HRTooltip>
                </Wrapper>
            </div>
            <div className="flex space-x-2 justify-between">
                <div className="flex space-x-2 opacity-100 lg:opacity-0 group-hover:opacity-100">
                    <span className="p-1 cursor-pointer" onClick={() => setShowDeleteModal(true)}>
                        <ICDelete fill="#A6A8B4" />
                    </span>
                    <span className="p-1 cursor-pointer" onClick={onMove}>
                        <ICFolder />
                    </span>
                    <span className="p-1 cursor-pointer" onClick={() => onUpdate(index)}>
                        <ICEdit fill="#A6A8B4" />
                    </span>
                </div>
                {/* add point here */}
                {hasActiveWeight && question?.type !== EQuestionType.ESSAY && <div>
                    <InputNumber
                        type="number"
                        onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                            }
                        }}
                        onChange={val => onUpdateWeight(val, index)}
                        min={0}
                        value={question?.weight as number || 0}
                        style={{
                            width: '85px',
                            borderRadius: '4px',
                        }}
                        max={1000}
                    />
                </div>}
                <div className="p-1 cursor-pointer" onClick={onChangeCollapsed}>
                    <ICChevronDown stroke="#A6A8B4" />
                </div>
            </div>
        </div>
        {
            question?.isExpand && <QuestionExplanation question={question} />
        }
        <Modal
            centered
            onCancel={() => setShowDeleteModal(false)}
            open={showDeleteModal}
            title={
                <div className="font-bold text-[18px]">{i18next.t('createTest.removeQuestion')}</div>
            }
            footer={
                <div className="grid grid-cols-[auto_auto] justify-end gap-[16px]">
                    <HRButton
                        btnType="sub"
                        btnSize="sm"
                        onClick={() => setShowDeleteModal(false)}>
                        {i18next.t('createTest.cancel')}
                    </HRButton>
                    <HRButton
                        btnSize="sm"
                        onClick={() => {
                            onDelete(index)
                            setShowDeleteModal(false)
                        }}>
                        {i18next.t('createTest.remove')}
                    </HRButton>
                </div>
            }
        >
            <p>{parse(i18next.t('createTest.sureToRemoveQuestion',{ t: questionNo }))}</p>
        </Modal>
    </div >
}

type QuestionExplanationProps = {
    question: TQuestion,
}
const QuestionExplanation: React.FC<QuestionExplanationProps> = ({
    question
}) => {
    const rightText = (() => {
        switch (question?.type) {
            case EQuestionType.ESSAY:
                return i18next.t('createTest.suggestReview');
            case EQuestionType.GROUP:
                return i18next.t('createTest.question');
            default:
                return i18next.t('createTest.answer', { t: null });
        }
    })();
    return <div className="text-left grid grid-cols-1 lg:grid-cols-2 gap-[24px] space-x-3 p-1 lg:p-8">
        <div className="flex flex-col gap-[8px]">
            <div className="text-standard-medium text-primary-bold">{i18next.t('createTest.question')}</div>
            <div className="grid overflow-auto">
                <span className="w-full h-min line-clamp-7 text-body text-high-em">
                    <span dangerouslySetInnerHTML={{
                        __html: question.content
                    }}></span>
                </span>
            </div>
        </div>
        <div className="grid">
            <div className={classNames(
                "flex flex-col",
                question?.type === EQuestionType.ESSAY ? "gap-[8px]" : "gap-[24px]"
            )}>
                <div className="text-standard-medium text-primary-bold">
                    {rightText}
                </div>
                {
                    // TODO: Need to support other type of question
                    [EQuestionType.SINGLE_CHOICE, EQuestionType.MULTIPLE_CHOICE].includes(question.type) && <AnswerWrapper
                        question={question as TChoiceQuestion}
                        viewOnly={true}
                    />
                }
                {question?.type === EQuestionType.ESSAY && <div className="grid overflow-auto">
                    <span className="w-full h-min line-clamp-7 text-body text-high-em">
                        <span dangerouslySetInnerHTML={{
                            __html: question.referenceContent
                        }}></span>
                    </span>
                </div>}
                {question?.type === EQuestionType.GROUP && <div className="grid overflow-auto">
                    {
                        question.questions.map((question, index) => {
                            return <div className="flex line-clamp-7 text-body text-high-em" key={index}>
                                <span>
                                    {index + 1}.&nbsp;
                                </span>
                                <span className="w-full h-min" dangerouslySetInnerHTML={{
                                    __html: question.content
                                }}></span>
                            </div>
                        })
                    }
                </div>}
            </div>
        </div>
    </div>
}
