import { HRButton } from "../../components/button"
import { ICClose } from "../../icons"

export const PrivacyPolicyEnPage: React.FC = () => {
    return (
        <div className="flex items-center justify-center w-full relative pt-[40px] pb-[40px] bg-surface-low">
            <div className="container mx-auto flex flex-col bg-white rounded-[12px] gap-[32px] shadow-e-03 py-[54px] px-[32px] relative">
                <div className="flex items-center justify-end">
                    <span>
                        <HRButton btnType="secondary" btnSize="sm"
                            onClick={() => {
                                // close window
                                window.close();
                            }}
                            >
                            <div className="flex gap-[8px] items-center">
                                <ICClose fill="#7357FF" height={18} width={18} />
                                <span className="text-body-medium text-primary">Close</span>
                            </div>
                        </HRButton>
                    </span>
                </div>
                <div className="flex flex-col gap-[32px]">
                    <h2 className="text-heading-4-bold text-high-em mb-0 text-left">Our Policy
                    </h2>
                    <div className="flex flex-col gap-[8px]">
                        <h4 className="text-standard-bold text-high-em mb-0">
                            INTRODUCTION
                        </h4>
                        <div className="flex flex-col gap-[8px]">
                            <span className="text-body text-high-em">
                                Welcome to the HR Product software via the website interface and (on mobile). Before using the HR Product software or creating an account (“Account”), please read these Terms of Use and the operational regulations carefully to better understand your legal rights and obligations regarding these Terms of Use (“Terms”, “Terms of Use”) governing the relationship between you and HR Product.
                            </span>
                            <span className="text-body text-high-em">
                                These Terms of Use apply to visitors, users, and others who access or use the service.
                            </span>
                            <span className="text-body text-high-em">
                                HR Product reserves the right to change, modify, suspend, or terminate all or any part of the HR Product Site or Service at any time in accordance with the law. The trial version of the Service may not be identical to the final version.
                            </span>
                        </div>
                    </div>
                    <div className="flex flex-col gap-[8px]">
                        <h4 className="text-standard-bold text-high-em mb-0">
                            TERMS OF USE
                        </h4>
                        <div className="flex flex-col gap-[8px] pl-[10px]">
                            <span className="text-body text-high-em">
                                (1) To use the service, you must ensure that you are at least eighteen (18) years old and legally capable of performing contractual obligations;
                            </span>
                            <span className="text-body text-high-em">
                                (2)	Complete the registration process;
                            </span>
                            <span className="text-body text-high-em">
                                (3)	Agree to the terms and policies;
                            </span>
                            <span className="text-body text-high-em">
                                (4)	Provide accurate, complete, and up-to-date contact and payment information;
                            </span>
                            <span className="text-body text-high-em">
                                (5)	By accessing, creating an account, and using the services at HR Product, you agree to abide by these terms and any additional policies. If you do not agree with any part of the terms, you may not access or use our services;
                            </span>
                        </div>
                    </div>

                    <div className="flex flex-col gap-[8px]">
                        <h4 className="text-standard-bold text-high-em mb-0">
                            CODE OF CONDUCT
                        </h4>
                        <span>
                            By agreeing to these Terms, you agree that during your use of the Service, you will adhere to the following rules:
                        </span>
                        <div className="flex flex-col gap-[8px] pl-[10px]">
                            <span className="text-body text-high-em">
                                (1)	By agreeing to these Terms, you agree that during your use of the Service, you will adhere to the following rules:
                            </span>
                            <span className="text-body text-high-em">
                                (2)	Do not engage in any illegal activities;
                            </span>
                            <span className="text-body text-high-em">
                                (3)	Do not upload, post, transmit, or otherwise publicly disclose any content that is unlawful, harmful, threatening, abusive, harassing, alarming, distressing, defamatory, offensive, pornographic, libelous, invasive of others' privacy, hateful, or racially, ethnically or otherwise objectionable; 
                            </span>
                            <span className="text-body text-high-em">
                                (4)	Do not engage in fraudulent, deceptive, or misleading activities; 
                            </span>
                            <span className="text-body text-high-em">
                                (5)	Do not use the application or its content in a way that causes us or any third party to be held liable to any third party;
                            </span>
                            <span className="text-body text-high-em">
                                (6)	Do not reverse engineer, decompile, copy, modify, distribute, transmit, license, sublicense, display, adapt, perform, transfer, sell, or provide any third party with any content of the application except as expressly permitted by us under these Terms or as required by applicable law; 
                            </span>
                            <span className="text-body text-high-em">
                                (7)	Do not destroy any access restrictions or ability to provide the Service;
                            </span>
                            <span className="text-body text-high-em">
                                (8)	Do not use the system to distribute viruses;
                            </span>
                            <span className="text-body text-high-em">
                                (9)	Do not engage in any actions that distort the rating system or feedback received by HR Product;
                            </span>
                            <span className="text-body text-high-em">
                                (10) Users are solely responsible for all costs and losses they may incur related to the use of the application and will be responsible for keeping their password and account details confidential.
                            </span>
                        </div>
                    </div>

                    <div className="flex flex-col gap-[8px]">
                        <h4 className="text-standard-bold text-high-em mb-0">
                            REGISTRATION
                        </h4>
                        <span className="text-body text-high-em">
                            Some parts of the Service are billed on a subscription basis (“(a) subscription(s)”). You will be billed in advance on a recurring and periodic basis (“Billing Cycle”). The Billing Cycle is set on a monthly basis, depending on the type of subscription plan you choose when purchasing a subscription. A minimum number of Billing Cycles may apply to a subscription.
                        </span>
                        <span className="text-body text-high-em">
                            At the end of each Billing Cycle, your subscription will automatically renew under the same exact conditions unless you cancel your subscription or HR Product cancels the subscription. You can cancel the renewal of your Subscription through your online account settings page or by contacting HR Product’s customer support team, provided you give at least 30 days' notice.
                        </span>
                        <span className="text-body text-high-em">
                            Valid payment methods, including credit cards or VNPAY, and electronic invoicing, are required to process payment for your subscription. You must provide HR Product with accurate and complete payment information, including full name, address, zip code, country, phone number, credit card number, and CVC number. By submitting such payment information, you automatically authorize HR Product to charge all subscription fees incurred through your account to any such payment method.
                        </span>
                        <span className="text-body text-high-em">
                            If the payment proceeds without issues, HR Product will issue an electronic invoice indicating that you have successfully subscribed to our Service package and the payment period specified on the invoice.
                        </span>
                    </div>

                    <div className="flex flex-col gap-[8px]">
                        <h4 className="text-standard-bold text-high-em mb-0">
                            FREE PACKAGE
                        </h4>
                        <span className="text-body text-high-em">
                            We offer a free usage package, so you will not be charged for using it.
                        </span>
                        <span className="text-body text-high-em">
                            At any time and without notice, we reserve the right to modify the terms of use of the Free Package offer or cancel the Free Package offer.
                        </span>
                    </div>
                    <div className="flex flex-col gap-[8px]">
                        <h4 className="text-standard-bold text-high-em mb-0">
                            FEE CHANGES
                        </h4>
                        <span className="text-body text-high-em">
                            The Premium package fees may change at any time at our discretion. Any changes in subscription fees will take effect at the end of the current Billing Cycle.
                        </span>
                        <span className="text-body text-high-em">
                            We will notify you in advance of any changes to subscription fees, so you have the opportunity to change or cancel before they take effect.
                        </span>
                        <span className="text-body text-high-em">
                            Your continued use of the service after changes to subscription fees take effect constitutes your agreement to pay the revised subscription fees.
                        </span>
                    </div>
                    <div className="flex flex-col gap-[8px]">
                        <h4 className="text-standard-bold text-high-em mb-0">
                            REFUND POLICY
                        </h4>
                        <span className="text-body text-high-em">
                            Recurring payment plans will be processed on the renewal date. Packages may be canceled at any time, and you will still be allowed access to the platform until the end of the current subscription. If a service package is canceled by you or us before the end of the current subscription, you will not be entitled to a refund. 
                        </span>
                        <span className="text-body text-high-em">
                            Prepaid fees and any other fees are non-refundable under any circumstances.
                        </span>
                    </div>
                    <div className="flex flex-col gap-[8px]">
                        <h4 className="text-standard-bold text-high-em mb-0">
                            CONTENT LICENSE AND INTELLECTUAL PROPERTY
                        </h4>
                        <span className="text-body text-high-em">
                            Our service allows you to post links, store, share, and provide certain information, text, graphics, videos, or other materials (“Content”). You are responsible for the content you post on the service, including its legality, reliability, accuracy, and appropriateness.
                        </span>
                        <span className="text-body text-high-em">
                            As the creator of the content you post, submit, or display through the service, you retain all intellectual property rights to such content and are solely responsible for protecting those rights. We acknowledge that posting or displaying such content on the service does not grant us an implied or express license to use the content on behalf of any third party. Therefore, we will not use such content in any manner except with your explicit written permission.
                        </span>
                        <span className="text-body text-high-em">
                            You represent and warrant that: The content is yours (you own the content) or you have the legal right to use the content, and the posting of your content through the service does not infringe on any privacy, data protection rights, copyright, or other intellectual property rights, contractual rights, or any other rights of any third party.
                        </span>
                    </div>

                    <div className="flex flex-col gap-[8px]">
                        <h4 className="text-standard-bold text-high-em mb-0">
                            AUTHORIZED USE OF THE SERVICE
                        </h4>
                        <span className="text-body text-high-em">
                            You may only use the service for lawful purposes and in a manner consistent with the nature and purpose of HR Product.
                        </span>
                        <span className="text-body text-high-em">
                            You must not use the service to assess candidates for any job in an unlawful, unsafe, offensive, discriminatory, or inappropriate manner.
                        </span>
                        <span className="text-body text-high-em">
                            You must not use the service to collect information from candidates that could be used to discriminate against them. Unauthorized use of the service may be considered a criminal offense and/or lead to claims for damages.
                        </span>
                        <span className="text-body text-high-em">
                            Unauthorized use of the service may be considered a criminal offense and/or lead to claims for damages.
                        </span>
                    </div>
                    <div className="flex flex-col gap-[8px]">
                        <h4 className="text-standard-bold text-high-em mb-0">
                            ACCOUNT
                        </h4>
                        <span className="text-body text-high-em">
                            When you create an account, you must provide us with accurate, complete, and up-to-date information at all times. Failure to do so constitutes a violation of the Terms, which may result in the termination of your account on our service.
                        </span>
                        <span className="text-body text-high-em">
                            You are responsible for protecting the password you use to access the service and for any activity or actions taken using your password, whether it is with our Service or third-party services.
                        </span>
                        <span className="text-body text-high-em">
                            You agree not to disclose your password to any third party. You must notify us immediately if you become aware of any security breaches or unauthorized use of your account.
                        </span>
                        <span className="text-body text-high-em">
                            You must not use a username that is the name of another person, organization, an illegal name, or another trademark. The name you register should not be associated with any person or organization other than yourself without permission. The name must not include offensive, vulgar, or obscene words.
                        </span>
                    </div>
                    <div className="flex flex-col gap-[8px]">
                        <h4 className="text-standard-bold text-high-em mb-0">
                            LINKS TO OTHER WEBSITES
                        </h4>
                        <span className="text-body text-high-em">
                            Our service may contain links to third-party websites or services that are not owned or controlled by HR Product. If you obtain an HR Product account from a third party, that third party may have additional rights regarding your accounts, such as the ability to access or delete your HR Product account. Review any additional terms provided by the third party, as HR Product is not responsible for these additional terms. If you create an HR Product account on behalf of an entity such as a business or your employer, you must ensure that you have the legal authority to bind that entity to these terms. You must not share your HR Product account login information with other users or organizations. To protect your account, keep your password and account details confidential. You are responsible for all activities that occur under your HR Product account.
                        </span>
                    </div>
                    <div className="flex flex-col gap-[8px]">
                        <h4 className="text-standard-bold text-high-em mb-0">
                            DATA PROTECTION
                        </h4>
                        <span className="text-body text-high-em">
                            You acknowledge that information about you and content posted will be collected, stored, and used by us in accordance with our Privacy Policy.
                        </span>
                    </div>
                    <div className="flex flex-col gap-[8px]">
                        <h4 className="text-standard-bold text-high-em mb-0">
                            LIMITATION OF LEGAL LIABILITY
                        </h4>
                        <span className="text-body text-high-em">
                            In no event shall HR Product, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential, or punitive damages, including but not limited to loss of profits, data, use, goodwill, or other intangible losses, arising from:
                        </span>
                        <div className="flex flex-col gap-[8px] pl-[10px]">
                            <span className="text-body text-high-em">
                                (1)	Your access to or use of or inability to access or use the Service;
                            </span>
                            <span className="text-body text-high-em">
                                (2)	Any actions or content of any third party on the Service; 
                            </span>
                            <span className="text-body text-high-em">
                                (3)	Any content obtained from the Service;
                            </span>
                            <span className="text-body text-high-em">
                                (4)	Unauthorized access, use, or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or not we have been informed of the possibility of such damages, and even if a remedy set forth here is found to have failed of its essential purpose. Such limitations will not apply if the damages result from willful misconduct or gross negligence of HR Product or its senior management. In any event, HR Product's aggregate liability under these Terms will be limited to the amounts you actually paid to HR Product in the last Billing Cycle.
                            </span>
                        </div>
                    </div>
                    <div className="flex flex-col gap-[8px]">
                        <h4 className="text-standard-bold text-high-em mb-0">
                            GOVERNING LAW
                        </h4>
                        <span className="text-body text-high-em">
                            These Terms shall be governed by and construed in accordance with the laws of Vietnam, regardless of conflicts with legal provisions.
                        </span>
                        <span className="text-body text-high-em">
                            All disputes between HR Product and you (whether involving third parties or not) will be submitted exclusively to the competent courts in Vietnam.
                        </span>
                        <span className="text-body text-high-em">
                            Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is found by a court to be invalid or unenforceable, the remaining provisions of these Terms will remain in full force and effect. These Terms constitute the entire agreement between us and supersede any prior agreements we might have between us regarding the Service.
                        </span>
                    </div>
                    <div className="flex flex-col gap-[8px]">
                        <h4 className="text-standard-bold text-high-em mb-0">
                            CHANGES
                        </h4>
                        <span className="text-body text-high-em">
                            We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If the revision is material, we will make reasonable efforts to provide at least 30 days' notice before any new terms take effect. What constitutes a material change will be determined at our sole discretion.
                        </span>
                        <span className="text-body text-high-em">
                            By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms. If you do not agree with the new terms, please stop using the Service.
                        </span>
                    </div>
                    <div className="flex flex-col gap-[8px]">
                        <h4 className="text-standard-bold text-high-em mb-0">
                            CONTACT US
                        </h4>
                        <span className="text-body text-high-em">
                            If you have any questions about these Terms, please contact us.
                        </span>
                    </div>

                    <div className="flex w-full bg-[#D9D8DC] h-[1px]"></div>
                    <h2 className="text-[32px] font-bold leading-[40px] mb-0 text-left">PRIVACY POLICY</h2>
                    <div className="flex flex-col gap-[8px]">
                        <h4>We want you to understand what types of information we collect when you use our services.</h4>
                        <h4 className="text-standard-bold text-high-em mb-0">
                            DATA PROTECTION PRINCIPLES
                        </h4>
                        <span className="text-body text-high-em">
                            We will comply with data protection laws. This means that the personal data we hold about you must be:
                        </span>
                        <span className="text-body text-high-em">
                            Used lawfully and transparently; collected only for legitimate purposes that we have clearly explained to you and not used in any manner incompatible with those purposes; Relevant to the purposes we have communicated to you and limited to those purposes; Accurate and kept up to date; Retained only for as long as necessary for the purposes we have communicated to you; and Kept secure.
                        </span>
                    </div>
                    <div>
                        <h4 className="text-standard-bold text-high-em mb-0">
                            WHAT TYPES OF INFORMATION DO WE COLLECT?
                        </h4>
                        <span className="text-body text-high-em">
                            We collect information that you provide to us, including: your name, email address, payment method, phone number, and other identifying information you may use. We collect this information in several ways, including: information you enter when using our service, interacting with our customer service, or participating in surveys or marketing promotions;
                        </span>
                        <span className="text-body text-high-em">
                            choices you make in your account settings (including options set in the "Account" section on our website) or information you provide through our service or third-party services.
                        </span>
                    </div>
                    <div className="flex flex-col gap-[6px]">
                        <h4 className="text-standard-bold text-high-em mb-0">
                            HOW DO WE USE YOUR PERSONAL DATA?
                        </h4>
                        <div className="flex flex-col gap-[10px]">
                            <span className="text-body text-high-em">
                                We use the information collected from our services for the following purposes:
                            </span>
                            <span className="text-body text-high-em">
                                <strong>Customer Management:</strong> We use the information to perform and manage the tests you have taken;
                            </span>
                            <span className="text-body text-high-em">
                                <strong>Communication:</strong> We use collected information, such as your email address, to communicate directly with you. Additionally, we may inform you about upcoming changes or improvements to our services. You may opt out of receiving all such communications from us by clicking the unsubscribe link;
                            </span>
                            <span className="text-body text-high-em">
                                <strong>Platform Optimization:</strong> To improve, test, and monitor the performance of the platform, and to diagnose and fix technical issues;
                            </span>
                            <span className="text-body text-high-em">
                                <strong>Vendor Management:</strong> For service providers who assist us;
                            </span>
                            <span className="text-body text-high-em">
                                <strong>Easy Access:</strong> To help you quickly access your account after logging in, we remember information so you do not have to re-enter it during subsequent visits;
                            </span>
                            <span className="text-body text-high-em">
                                <strong>Statistics:</strong> To track metrics such as traffic, demographic patterns, and patterns in our test results (on an anonymous and aggregated basis);
                            </span>
                            <span className="text-body text-high-em">
                                <strong>Service Maintenance and Improvement:</strong> We also use your information to ensure our services function as intended. Additionally, we use your information to enhance our services;
                            </span>
                            <span className="text-body text-high-em">
                                <strong>Development:</strong> To develop and test new products and features;
                            </span>
                            <span className="text-body text-high-em">
                                <strong>Benchmarking:</strong> To use aggregated and anonymized test scores and demographic information to provide benchmarks for clients and improve our services.
                            </span>
                        </div>
                    </div>
                    <div className="flex flex-col gap-[8px]">
                        <h4 className="text-standard-bold text-high-em mb-0">
                            WHY WE USE YOUR PERSONAL DATA
                        </h4>
                        <span className="text-body text-high-em">
                            We process your personal data for several reasons:
                        </span>
                        <span className="text-body text-high-em">
                            1. You have given us consent;
                        </span>
                        <div className="text-body text-high-em">
                            <span className="text-body text-high-em">
                                2. The processing is necessary for our legitimate business interests or those of a third party, provided this does not override any interests or rights you have as an individual. Our legitimate interests include:
                            </span>
                            <div className="flex flex-col gap-[8px] pl-[12px]">
                                <span className="text-body text-high-em">
                                    (1)	Managing our business and our relationship with you or your company or organization;
                                </span>
                                <span className="text-body text-high-em">
                                    (2)	Understanding and responding to user inquiries and feedback;
                                </span>
                                <span className="text-body text-high-em">
                                    (3)	Understanding how users use the platform;
                                </span>
                                <span className="text-body text-high-em">
                                    (4)	Identifying what you want and developing our relationship with you or your company or organization;
                                </span>
                                <span className="text-body text-high-em">
                                    (5)	Improving the platform and services;
                                </span>
                                <span className="text-body text-high-em">
                                    (6)	Managing the supply chain;
                                </span>
                                <span className="text-body text-high-em">
                                    (7)	Developing relationships with business partners;
                                </span>
                                <span className="text-body text-high-em">
                                    (8)	Sharing data related to mergers and acquisitions and business transfers.
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col gap-[8px]">
                        <h4 className="text-standard-bold text-high-em mb-0">
                            WHO DO WE SHARE YOUR PERSONAL DATA WITH?
                        </h4>
                        <span className="text-body text-high-em">
                            <strong>Clients:</strong> We share information about HR Product's software with clients (potential), to inform them about HR Product’s credentials and information about the software provided by HR Product itself. We will not rent or sell your information to any third parties;
                        </span>
                        <span className="text-body text-high-em">
                            <strong>Test Results:</strong> We share information about test takers with the businesses that created the tests; we share feedback from test takers with businesses regarding the tests.
                        </span>
                        <span className="text-body text-high-em">
                            <strong>Suppliers:</strong> Those who support our business operations, including IT and communications providers, outsourcing business support, business intelligence providers, marketing and advertising agents, and backup suppliers. Our suppliers must meet information security standards and will only receive data necessary for their functions.
                        </span>
                        <span className="text-body text-high-em">
                            <strong>Change of Control:</strong> If we sell or transfer all or part of HR Product or our assets to another organization (e.g., during transactions such as mergers, acquisitions, bankruptcy, dissolution, or liquidation), your information and any other data collected through the platform may be among the items sold or transferred. The buyer or transferee will be required to honor the commitments we have made in this Privacy Policy.
                        </span>
                    </div>
                    <div className="flex flex-col gap-[8px]">
                        <h4 className="text-standard-bold text-high-em mb-0">
                            SECURITY AND SAFETY
                        </h4>
                        <span className="text-body text-high-em">
                            We use administrative, logical, physical, and managerial measures to protect your personal information from loss, theft, and unauthorized access, use, and modification.
                        </span>
                        <span className="text-body text-high-em">
                            These measures are designed to provide a level of security appropriate to the risks of processing your personal information. However, we cannot guarantee the security of any information you transmit to us or ensure that information on the platform cannot be accessed, disclosed, altered, or destroyed. We require you to play a part in helping us. You are responsible for maintaining the security of your account information and unique password and controlling access to email between you and us at all times. We are not responsible for the functionality or privacy of your information.
                        </span>
                    </div>
                    <div className="flex flex-col gap-[8px]">
                        <h4 className="text-standard-bold text-high-em mb-0">
                            INFORMATION AND YOUR RIGHTS
                        </h4>
                        <span className="text-body text-high-em">
                            You have the right to access your personal information or correct or update outdated or inaccurate personal information that we hold. You may also request that we delete personal information that we hold.
                        </span>
                        <span className="text-body text-high-em">
                            When you access the "Account" section on our website, you can access and update a substantial amount of information about your account, including contact information, payment information, and other relevant account information. You must log in to access the "Account" section.
                        </span>
                        <span className="text-body text-high-em">
                            We may refuse unreasonable or legally unnecessary requests, including those that are impractical, require disproportionate technical effort, or could expose us to operational risks such as fraud.
                        </span>
                        <span className="text-body text-high-em">
                            Information Retention: We may retain information as required or permitted by applicable laws and regulations, including in accordance with your choices, for billing or record-keeping purposes, and to fulfill the purposes described in this Privacy Statement. Specifically, we retain information for our legitimate interests and essential business purposes, such as operating, maintaining, and improving the service; complying with legal obligations; and enforcing legal rights and claims, including enforcing our Terms of Use. Our information retention is based on factors such as your relationship with HR Product (e.g., whether you are a current member of HR Product); the nature of the information; compliance with our legal obligations; defending or resolving actual or anticipated legal claims. We also take reasonable measures to carefully delete or destroy personal identification information when it is no longer needed.
                        </span>
                    </div>
                    <div className="flex flex-col gap-[8px]">
                        <h4 className="text-standard-bold text-high-em mb-0">
                            THIRD-PARTY APPLICATIONS, WEBSITES, AND SERVICES
                        </h4>
                        <span className="text-body text-high-em">
                            We are not responsible for the activities conducted by any application, website, or service linked to or from our platform, including any information or content contained therein. Please remember that when you use a link to navigate from our platform to another application, website, or service, the Privacy Policy does not apply to those third-party applications, websites, or services. Your browsing and interaction on any third-party application, website, or service, including those websites linked on our Platform, will be subject to the third party's own rules and policies. Additionally, you agree that we are not responsible and have no control over any third parties that you allow to access your account. If you use a third-party application, website, or service and grant them access to your account, you do so at your own risk.
                        </span>
                    </div>
                    <div className="flex flex-col gap-[8px]">
                        <h4 className="text-standard-bold text-high-em mb-0">
                            HOW LONG DO WE RETAIN YOUR DATA?
                        </h4>
                        <span className="text-body text-high-em">
                            We generally retain your information only as long as necessary to provide the services on our Platform. We will keep your information as required to comply with legal, accounting, or regulatory requirements. The retention period for test taker data available to customers (e.g., name, email address, and test scores) is 5 years. Webcam images captured as a fraud prevention measure are retained for 6 months. Video recordings of test takers answering custom questions are retained for 2 years.
                        </span>
                    </div>
                    <div className="flex flex-col gap-[8px]">
                        <h4 className="text-standard-bold text-high-em mb-0">
                            COOKIE
                        </h4>
                        <span className="text-body text-high-em">
                            We use cookies to identify your browser. It collects and stores information about how you use it when accessing the platform, which can record your use of the platform, provide you with services, and enhance your experience. Personal data collected through these technologies will also be used to manage your session. For more information about cookies and how we use them, please refer to our <strong>Cookie Policy</strong>.
                        </span>
                    </div>
                    <div className="flex flex-col gap-[8px]">
                        <h4 className="text-standard-bold text-high-em mb-0">
                            CHILDREN
                        </h4>
                        <span className="text-body text-high-em">
                            Our platform is not directed at anyone under 16 years of age (“Children”). We do not knowingly collect personal information from children under 16 years old. If you are a parent or guardian and you know that your child has provided us with personal data, please contact us. If we become aware that we have collected personal data from a child under 16 without parental consent verification, we will take steps to delete that information from our servers.
                        </span>
                    </div>
                    <div className="flex flex-col gap-[8px]">
                        <h4 className="text-standard-bold text-high-em mb-0">
                            CHANGES TO THIS POLICY
                        </h4>
                        <span className="text-body text-high-em">
                            We may modify or update our Privacy Policy. We will notify you of any changes by posting the new Privacy Policy on this page. You should review this Privacy Policy periodically for any changes. Changes to this Privacy Policy will be effective when they are posted on this page.
                        </span>
                    </div>
                    <div className="flex flex-col gap-[8px]">
                        <h4 className="text-standard-bold text-high-em mb-0">
                            CONTACT US
                        </h4>
                        <span className="text-body text-high-em">
                            If you have any questions about this Privacy Policy, <strong>please contact us.</strong>
                        </span>
                    </div>
                    <div className="flex w-full bg-[#D9D8DC] h-[1px]"></div>

                    <h2 className="text-[32px] font-bold leading-[40px] mb-0">COOKIE POLICY</h2>
                    <div className="flex flex-col gap-[8px]">
                        <span className="text-body text-high-em">
                            Thank you for visiting www.HRProduct.com (“Website”). This Cookie Notice applies to the Website, any third-party websites, and applications accessed or used through these websites or platforms operated by or on behalf of HR Product (“HR Product Website”).
                        </span>
                        <span className="text-body text-high-em">
                            By using the HR Product Website, you consent to our use of cookies and other tracking technologies as described in this notice. If you do not agree to our use of cookies and other tracking technologies in this manner, you should adjust your browser settings accordingly or not use the HR Product Website. If you disable cookies that we use, it may affect your user experience when accessing the HR Product Website.
                        </span>
                        <span className="text-body text-high-em">
                            When using a mobile device to connect to the internet, you should also review the privacy notice of the specific application you are using to understand its data collection practices.
                        </span>
                    </div>
                    <div className="flex flex-col gap-[8px]">
                        <h4 className="text-standard-bold text-high-em mb-0">
                            WHAT ARE COOKIES?
                        </h4>
                        <span className="text-body text-high-em">
                            Cookies are small text files containing a small amount of information that are downloaded to your device when you access our Website. Other technologies (including data we store on your web browser or device, device-related identification information, and other software) are used for similar purposes. In this policy, we refer to all these technologies as “cookies.”
                        </span>
                        <span className="text-body text-high-em">
                            We use cookies if you have an HR Product account, use features, or access other websites and applications. This policy explains how we use cookies and provides options for you. Unless otherwise stated in this policy, our Privacy Policy applies to the processing of data collected through cookies.
                        </span>
                    </div>
                    <div className="flex flex-col gap-[8px]">
                        <h4 className="text-standard-bold text-high-em mb-0">
                            HOW DOES HR PRODUCT USE COOKIES?
                        </h4>
                        <span className="text-body text-high-em">
                            HR Product uses cookies to gain a better understanding of how visitors use this website. Cookies help us tailor the HR Product website to your personal needs, improve user friendliness, collect feedback on customer satisfaction on our site (through designated partners), and communicate with you elsewhere on the web. To enable these features, some cookies are applied when you visit our site.
                        </span>
                        <span className="text-body text-high-em">
                            HR Product keeps all information collected from cookies in a non-personally identifiable format. HR Product cookies stored on your computer do not retain your name or IP address.
                        </span>
                    </div>
                    <div className="flex flex-col gap-[8px]">
                        <h4 className="text-standard-bold text-high-em mb-0">
                            WHAT TYPES OF COOKIES DOES HR PRODUCT USE?
                        </h4>
                        <div>
                            <span className="text-body text-high-em">
                                The following types of cookies are used on HR Product websites. Depending on the purpose, we use the following types of cookies:
                            </span>
                            <div className="flex flex-col gap-[8px] pl-[12px]">
                                <span className="text-body text-high-em">
                                    (1)	Session Cookies –  These cookies appear only during the current browsing session and will expire after a short period. Once you close your browser, all session cookies are deleted. When you restart your browser and return to the website that set the cookie, the site will treat you as a new visitor.
                                </span>
                                <span className="text-body text-high-em">
                                    (2)	Persistent Cookies – These cookies are stored on your device between browsing sessions until they expire or are deleted. They allow the website to recognize you, remember your preferences, and tailor services to you.
                                </span>
                                <span className="text-body text-high-em">
                                    (3)	Third-Party Cookies – These cookies are set by third parties who have been approved to use them.
                                </span>
                            </div>
                            <span className="text-body text-high-em">
                            Our website may use the following types of cookies:
                            </span>
                        </div>
                    </div>
                    <div className="flex flex-col gap-[8px]">
                        <h4 className="text-standard-bold text-high-em mb-0">
                            Website Optimization Cookies
                        </h4>
                        <span className="text-body text-high-em">
                            These cookies are essential for the operation of our website. Our website will not function properly without these cookies. You cannot disable these cookies in our system, and they do not store any personal identification information. These cookies allow our website to perform tasks such as storing information. If you modify your browser to block all cookies, we cannot guarantee that our website will function correctly when you access it.
                        </span>
                    </div>
                    <div className="flex flex-col gap-[8px]">
                        <h4 className="text-standard-bold text-high-em mb-0">
                            Functionality Cookies
                        </h4>
                        <span className="text-body text-high-em">
                            These cookies are set to enhance the functionality of the website. They help remember your choices (such as language or region) and are used to provide an improved web experience. All information collected is in an aggregated form and is therefore anonymized. This information helps us continuously improve the quality of our website.
                        </span>
                    </div>
                    <div className="flex flex-col gap-[8px]">
                        <h4 className="text-standard-bold text-high-em mb-0">
                            Targeting and Advertising Cookies
                        </h4>
                        <span className="text-body text-high-em">
                            These cookies are often used for marketing purposes to provide visitors with more relevant information. These cookies are usually set by us or by third-party advertising networks that we authorize. The information collected through these cookies is shared with other third-party organizations such as advertising companies, who may use this information to deliver advertisements and/or targeted content to you on other websites. Typically, targeting or advertising cookies are linked to functionality provided by another organization.
                        </span>
                    </div>
                    <div className="flex flex-col gap-[8px]">
                        <h4 className="text-standard-bold text-high-em mb-0">
                            Cookies that Send Information to Us
                        </h4>
                        <span className="text-body text-high-em">
                            These are cookies that we place on the HR Product website and only the HR Product website can read them. These are known as "First-Party Cookies."
                        </span>
                        <span className="text-body text-high-em">
                            We also place cookies on advertisements displayed on other third-party owned websites. We collect information through these cookies when you click on or interact with advertisements. In this case, the advertisements will place "Third-Party Cookies." We may use the information obtained from these cookies to provide you with relevant advertisements based on your previous online behavior.
                        </span>
                    </div>
                    <div className="flex flex-col gap-[8px]">
                        <h4 className="text-standard-bold text-high-em mb-0">
                            Cookies that Send Information to Other Companies
                        </h4>
                        <span className="text-body text-high-em">
                            These are cookies placed on the HR Product website by our partner companies. They may use the data collected from these cookies to deliver anonymous targeted advertising to you on other websites, based on your visits to this website.
                        </span>
                    </div>
                    <div className="flex flex-col gap-[8px]">
                        <h4 className="text-standard-bold text-high-em mb-0">
                            Third-Party Cookies
                        </h4>
                        <span className="text-body text-high-em">
                            To provide the best website experience and to better understand the needs of our visitors, we may use applications or functionalities provided by third parties (such as advertising networks, web traffic analytics providers, etc.). This functionality may allow these third parties to set cookies on your system, which is beyond our control. These cookies may be performance/analytics tracking cookies or targeting/advertising cookies. For more details, please refer to the Third-Party Cookies we use.
                        </span>
                    </div>
                    <div className="flex flex-col gap-[8px]">
                        <h4 className="text-standard-bold text-high-em mb-0">
                            LÀM CÁCH NÀO ĐỂ THAY ĐỔI CÀI ĐẶT COOKIE CỦA TÔI?
                        </h4>
                        <span className="text-body text-high-em">
                            Vui lòng đảm bảo rằng cài đặt máy tính của bạn phản ánh việc bạn có vui lòng chấp nhận cookie hay không. Bạn có thể đặt trình duyệt của mình cảnh báo bạn trước khi chấp nhận cookie hoặc bạn chỉ cần đặt trình duyệt từ chối chúng, mặc dù bạn có thể không có quyền truy cập vào tất cả các tính năng của trang web này nếu bạn làm như vậy. Xem nút 'trợ giúp' trên trình duyệt của bạn để biết cách thực hiện việc này. Bạn không cần phải bật cookie để sử dụng hoặc điều hướng qua nhiều phần của Trang web HR Product. Hãy nhớ rằng nếu bạn sử dụng các máy tính khác nhau ở các địa điểm khác nhau, bạn sẽ cần đảm bảo rằng mỗi trình duyệt được điều chỉnh để phù hợp với tùy chọn cookie của bạn.
                        </span>
                        <span className="text-body text-high-em">
                            Để xóa cookie và tất cả thông tin đã thu thập, cũng như thay đổi cài đặt cookie của bạn, vui lòng nhấp vào liên kết sau.
                        </span>
                        <span className="text-body text-high-em">
                            {'<Đường link>'}
                        </span>
                        <span className="text-body text-high-em">
                            Trang web cho phép bạn chọn KHÔNG cho phép các dịch vụ tạo và sử dụng các cookie này chạy, mặc dù bạn có thể không có quyền truy cập vào tất cả các tính năng của Trang web nếu bạn làm như vậy.
                        </span>
                        <span className="text-body text-high-em">
                            Để biết thông tin hữu ích về cookie, hãy xem AboutCookies.org
                        </span>
                        <span className="text-body text-high-em">
                            Cập nhật thông báo pháp lý
                        </span>
                        <span className="text-body text-high-em">
                            Chúng tôi bảo lưu quyền thực hiện bất kỳ thay đổi và chỉnh sửa nào đối với thông báo này. Thỉnh thoảng hãy tham khảo trang này để xem lại những thông tin này và thông tin bổ sung mới.
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}
