import { CloudOutlined, GlobalOutlined, HighlightOutlined, LogoutOutlined, UserOutlined } from "@ant-design/icons";
import { Drawer, Menu } from "antd";
import { ICBrand, ICCheckFill, ICCredit, ICLogout, ICScreen, ICUserV2 } from "../icons";
import { useNavigate } from "react-router-dom";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { removeUserInfo } from "../redux/actions";
import { setLoading } from "../redux/slices/appInfo";
import { logout } from "../api/auth";
import i18next from '../i18n';
import { useTranslation } from "react-i18next";
import { LANGUAGE } from "../utils/constants";

type MobileMenuDrawerProps = {
    isOpen: boolean;
    onClose: () => void;
}


export const MobileMenuDrawer: React.FC<MobileMenuDrawerProps> = ({
    isOpen,
    onClose
}) => {
    const { i18n } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const onRedirect = (path: string) => {
        onClose();
        navigate(path);
    }
    const onLogoutUser = useCallback(async () => {
        dispatch(setLoading(true));
        try {
            await logout();
        } catch (e) {
            console.error(e)
        }
        finally {
            dispatch(setLoading(false));
        }
    }, [dispatch]);
    const onLogout = useCallback(() => {
        onLogoutUser();
        dispatch(removeUserInfo());
        navigate("/login");
    }, [dispatch, navigate, onLogoutUser]);

    const onChangeLanguages = (key: LANGUAGE) => {
        localStorage.setItem("language", key);
        i18n.changeLanguage(key);
        
        window.location.reload(); 
    };

    return <Drawer
        title="Menu"
        onClose={onClose}
        open={isOpen}
        bodyStyle={{ padding: 0 }}
    >
        <div>
            <Menu
                defaultOpenKeys={['account']}
                mode="inline"
                items={[
                    {
                        key: 'account',
                        label: i18next.t('home.account'),
                        icon: <UserOutlined />,
                        children: [
                            {
                                key: 'my-profile',
                                label: <div className="flex space-x-2 items-center" onClick={() => onRedirect("/profile")}>
                                    <ICUserV2 width={20} height={20} />
                                    <span>{i18next.t('layout.myProfile')}</span>
                                </div>
                            },
                            {
                                key: 'my-account',
                                label: <div className="flex space-x-2 items-center" onClick={() => onRedirect("/account")}>
                                    <ICCredit
                                        width={20} height={20}
                                    /*fill={isActive ? "#7357FF" : "#8D8A95"} */
                                    />
                                    <span>{i18next.t('home.account')}</span>
                                </div>
                            },
                            {
                                key: 'my-brand',
                                label: <div className="flex space-x-2 items-center" onClick={() => onRedirect("/company")}>
                                    <ICBrand
                                        width={20} height={20}
                                        fill="#8D8A95"
                                    /*fill={isActive ? "#7357FF" : "#8D8A95"} */
                                    />
                                    <span>{i18next.t('home.brandEstablishment')}</span>
                                </div>
                            },
                            {
                                key: 'my-devices',
                                label: <div className="flex space-x-2 items-center" onClick={() => onRedirect("/devices")}>
                                    <ICScreen
                                        width={20} height={20}
                                    /*fill={isActive ? "#7357FF" : "#8D8A95"} */
                                    />
                                    <span>{i18next.t('home.device')}</span>
                                </div>
                            },
                        ],
                    },
                    {
                        type: 'divider',
                    },
                    {
                        key: 'assessment',
                        label: <div onClick={() => onRedirect("/my-assessment")}>{i18next.t('layout.assessment')}</div>,
                        icon: <HighlightOutlined />
                    },
                    {
                        type: 'divider',
                    },
                    {
                        key: 'candidate',
                        label: <div onClick={() => onRedirect("/my-candidate")}>{i18next.t('layout.candidate')}</div>,
                        icon: <UserOutlined />
                    },
                    {
                        type: 'divider',
                    },
                    {
                        key: 'library',
                        label: <div onClick={() => onRedirect("/library")}>{i18next.t('layout.library')}</div>,
                        icon: <CloudOutlined />
                    },
                    {
                        type: 'divider',
                    },
                    {
                        key: 'language',
                        label: i18next.t('layout.language'),
                        icon: <GlobalOutlined />,
                        children: [
                            {
                                key: 'vietnamese',
                                label: <div className="flex justify-between items-center" onClick={() => onChangeLanguages(LANGUAGE.VN)}><span>{i18next.t('layout.vietnamese')}</span> {i18n.language === LANGUAGE.VN && <ICCheckFill width={22} height={22} />}</div>
                            },
                            {
                                key: 'english',
                                label: <div className="flex justify-between items-center" onClick={() => onChangeLanguages(LANGUAGE.EN)}><span>{i18next.t('layout.english')}</span> {i18n.language === LANGUAGE.EN && <ICCheckFill width={22} height={22} />}</div>
                            }
                        ],
                    },
                    {
                        type: 'divider',
                    },
                    {
                        key: 'logout',
                        label: <div className="text-standard-medium text-[#F03D3D]" onClick={onLogout}>{i18next.t('layout.signOut')}</div>,
                        icon: <LogoutOutlined />
                    }
                ]}
            />
        </div>
    </Drawer>
}